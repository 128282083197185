import * as React from "react"

const IndexPage = ({ data }) => {
    /*React.useEffect(() => {
        navigate('/entity/root.html');
    }, []);*/
    //navigate('/entity/root.html');
    if (typeof window !== 'undefined') window.location.replace('/entity/root.html');
    return <></>;
}

export default IndexPage;
